.right-border {
  border-right: 1px solid #333;
}

.bg-clients {
  overflow: hidden;
}

.slogan-home{
  font-size: 6rem;
}

.col-kpi-home {
  opacity: 0;
}

.col-kpi-home.visible {
  opacity: 1;
	-webkit-animation: fade-in-right 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-right 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.bg-emotional{
  background-color: #141414;
  height: 90vh;
}

.label-emotional{
  color: #fff;
  font-size: 10rem;
  text-align: center;
  line-height: normal;
  filter: drop-shadow(5px 5px 0px #b4b4b4);
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.zoom-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.world-map {
  width: 100%;
  transform-origin: bottom left;
  animation: zoomEffect 50s linear infinite alternate;
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
    transform-origin: center center; 
  }
  50% {
    transform: scale(1.5);
    transform-origin: top right; 
  }
  100% {
    transform: scale(1);
    transform-origin: bottom left; 

  }
}


@media (max-width: 480px) {
  .slogan-home{
    font-size: 3rem;
  }
  .label-emotional{
    font-size: 5rem;
  }
}