.event-content {
  background-color: #f8f9fa;
  min-height: 100vh;

  .event-header {
    padding: 2rem 0;

    h1 {
      color: #141414;
    }
  }

  .nav-tabs-container {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .section-content {
    padding: 2rem 0;

    .card {
      transition: transform 0.2s;
      &:hover {
        transform: translateY(-5px);
      }
    }

    .photo-card {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
  }
}

.avatar-band {
  width: 80%;
  margin: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  & img {
    width: 100%;
    object-fit: cover;
  }
}

// Responsive
@media (max-width: 768px) {
  .event-content {
    .event-header {
      h1 {
        font-size: 2rem;
      }
    }
  }
  .img-viewer-syd {
    width: 33.33% !important;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    height: auto !important;
    margin: 0 !important;
  }
}

.event-page {
  background-color: #0a0a0a;
  color: #ffffff;
  min-height: 100vh;
}

// Hero Section
.hero-section {
  position: relative;
  height: 90vh;
  overflow: hidden;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); // Overlay scuro per migliorare la leggibilità
    z-index: 2;
  }

  .hero-content {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    text-align: end;
    color: #ffffff;
    padding: 3rem;

    .event-title {
      font-size: 4.5rem;
      font-weight: 800;
      margin: 0;
      text-transform: uppercase;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .event-date {
      font-size: 2.5rem;
      margin: 1rem 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    .event-location {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

      .location-dot {
        width: 8px;
        height: 8px;
        background-color: #fece2f;
        border-radius: 50%;
        animation: pulse 2s infinite;
      }
    }
  }
}

// Navigation
.nav-container {
  position: sticky;
  top: 64px; // Altezza dell'AppBar
  z-index: 100;
  background: rgba(20, 20, 20, 0.95);
  backdrop-filter: blur(10px);
  height: 72px; // Altezza fissa per la nav
  display: flex;
  align-items: center;

  .nav-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
    background: #191919;
    border-radius: 0.5rem;

    .nav-tab {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.8rem 1.5rem;
      border: none;
      background: transparent;
      color: #ffffff;
      border-radius: 30px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &.active {
        background: linear-gradient(45deg, #fece2f, #ff9933);
        box-shadow: 0 4px 15px rgba(255, 51, 102, 0.3);
        color: #141414;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }
}

// News Section
.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;

  .news-card {
    background: #1a1a1a;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-10px);
    }

    &.featured {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .news-image {
      height: 200px;
      background-size: cover;
      background-position: center;
    }

    .news-content {
      padding: 1.5rem;

      .news-tag {
        background: linear-gradient(45deg, #fece2f, #ff9933);
        color: #141414;
        font-weight: bold;
        padding: 0.3rem 0.8rem;
        border-radius: 20px;
        font-size: 0.8rem;
        text-transform: uppercase;
      }

      h3 {
        margin: 1rem 0;
        font-size: 1.5rem;
      }

      .news-date {
        color: #666;
        font-size: 0.9rem;
      }
    }
  }
}

// Band Section
.band-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem;

  .band-card {
    background: #1a1a1a;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 4px 4px 0 #323232;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 30px rgba(255, 51, 102, 0.2);

      .band-image {
        transform: scale(1.05);
      }
    }

    .band-image {
      height: 300px;
      background-size: cover;
      background-position: center;
      position: relative;
      transition: transform 0.3s ease;

      .band-time {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: rgba(0, 0, 0, 0.8);
        padding: 0.5rem 1rem;
        border-radius: 20px;
      }
    }

    .band-info {
      padding: 1.5rem;

      h3 {
        margin: 0 0 0.5rem 0;
      }

      .band-genre {
        color: #fece2f;
        font-size: 0.9rem;
      }
    }
  }
}

// Photo Gallery
.photo-gallery {
  padding: 2rem;

  .photo-grid {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .photo-item {
      // aspect-ratio: 1;
      // overflow: hidden;
      // border-radius: 10px;
      // cursor: pointer;
      // border: 1px solid red;
      height: 180px;
      width: max-content;

      img {
        // width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

// Info Section
.info-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;

  .info-card {
    background: #1a1a1a;
    border-radius: 15px;
    padding: 1.5rem;

    h3 {
      margin: 0 0 1rem 0;
      color: #fece2f;
    }

    .map-container {
      height: 300px;
      background: #2a2a2a;
      border-radius: 10px;
      margin-bottom: 1rem;
    }

    .timeline {
      .timeline-item {
        display: flex;
        gap: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid #2a2a2a;

        .time {
          color: #ff3366;
          font-weight: bold;
        }
      }
    }
  }
}

// Animations
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Responsive
@media (max-width: 768px) {
  .hero-section {
    .hero-content {
      .event-title {
        font-size: 3rem;
      }
    }
  }

  .nav-container .nav-tabs {
    gap: 0.5rem;

    .nav-tab {
      padding: 0.5rem 1rem;

      span {
        display: none;
      }
    }
  }
}

.content-container {
  padding-top: 2rem;
  min-height: 100vh; // Assicura che ci sia abbastanza spazio per scrollare
}

// News Modal Styles
.news-modal {
  .MuiDialog-paper {
    background-color: #1a1a1a;
    color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
  }

  .modal-header {
    padding: 1.5rem;
    background: linear-gradient(to right, #1a1a1a, #2a2a2a);

    .modal-title {
      h2 {
        margin: 0.5rem 0;
        font-size: 2rem;
      }

      .news-tag {
        background: linear-gradient(45deg, #fece2f, #ff9933);
        color: #141414;
        padding: 0.3rem 0.8rem;
        border-radius: 20px;
        font-size: 0.8rem;
        text-transform: uppercase;
        display: inline-block;
      }

      .news-date {
        color: #666;
        font-size: 0.9rem;
        display: block;
        margin-top: 0.5rem;
      }
    }

    .close-button {
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: #ffffff;
    }
  }

  .modal-content {
    padding: 0 !important;

    .news-hero-image {
      width: 100%;
      height: 300px;
      min-height: 300px;
      background-size: cover;
      background-position: center;
    }

    .news-body {
      padding: 2rem;
      font-size: 1.1rem;
      line-height: 1.6;

      h3 {
        color: #fece2f;
        margin: 1.5rem 0 1rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }
  }
}

// Aggiorna gli stili delle news card per mostrare che sono cliccabili
.news-card {
  cursor: pointer;

  &:hover {
    .news-image {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
  }
}

.input-div {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(1, 235, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: rgb(1, 235, 252);
  flex-direction: column;
  text-decoration: none;
  font-size: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: rgb(1, 235, 252) !important;
    box-shadow: 0px 0px 10px rgb(1, 235, 252),
      inset 0px 0px 10px rgb(1, 235, 252), 0px 0px 5px rgb(255, 255, 255);
  }
}

.icon {
  color: rgb(1, 235, 252);
  font-size: 2.5rem;
  cursor: pointer;
  animation: iconflicker 2s linear infinite;
}

.input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

@keyframes iconflicker {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0.2;
  }

  10% {
    opacity: 1;
  }

  25% {
    opacity: 0.2;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#ReactSimpleImageViewer {
  z-index: 1100;
  background: #000000cc;
  backdrop-filter: blur(3px);
}

.image-viewer-syd {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
}

.img-viewer-syd {
  height: 180px;
  width: max-content;
  margin: 3px;
  transition: transform 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
}
