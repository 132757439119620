.chip-wiki {
  border: 1px solid transparent !important;
  &:hover {
    background-color: #363636 !important;
    color: #f6f6f6;
  }
  &.selected {
    background-color: #141414 !important;
    color: #fece2f !important;
    border: 1px solid #fece2f !important;
  }
}

.icon-popular-card-wiki {
  color: #fece2f;
  font-size: 18px !important;
  z-index: 9999;
  position: absolute;
  top: 6px;
  right: 10px;
}

.label-tag-wiki {
  width: max-content;
  font-size: 12px;
  background: #000;
  color: #fece2f;
  font-weight: bold;
  padding: 0.2rem;
}

.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 18rem;
  min-height: 7rem;
  height: max-content;
  background: #29292c;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #f9d14c, #ffc400, #fe892f);
  --color: #fece2f;
}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #18181b;
  z-index: 2;
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notititle {
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  transition: transform 300ms ease;
  z-index: 5;
  color: #99999d;
  font-size: 0.8rem;
}

.notification:hover .notititle {
  transform: translateX(0.15rem);
}

.notibody {
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
  color: var(--color);
  font-weight: 600;
}

.notification:hover .notibody {
  transform: translateX(0.25rem);
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle closest-side at center,
    white,
    transparent
  );
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1;
}

.notification:hover .notiborderglow {
  opacity: 0.1;
}

.wiki-container {
  .content-container-syd {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .search-section {
    position: relative;
  }

  .link-wiki {
    height: 100%;
    display: flex;
    width: max-content;
    margin-top: 1rem;
    svg {
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      svg {
        transform: scale(1.05);
        filter: drop-shadow(2px 2px 5px #00000033);
      }
    }
  }

  @media (max-width: 767px) {
    .MuiCard-root {
      border-radius: 20px 20px 0 0;
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}

@media (max-height: 600px) and (max-width: 767px) {
  .wiki-container .MuiCard-root {
    max-height: 50vh;
  }
}
